<template>
  <div>
    <sidebar-right :visible="isOpenCreateForm" title="Создать паблишера" @hide="isOpenCreateForm = false">
      <create-form v-if="isOpenCreateForm" @success="handleCreate" @error="handleError" :data="{type: 'publisher'}" />
    </sidebar-right>

    <sidebar-right :visible="!!editAdvertiser" title="Редактировать паблишера" @hide="editAdvertiser = null">
      <update-form
          v-if="!!editAdvertiser"
          :is-visible="!!editAdvertiser"
          :data="{ user: editAdvertiser, type: 'publisher' }"
          @success="handleUpdate"
          @error="handleError"
      />
    </sidebar-right>

    <b-card no-body>
      <b-row class="my-2 px-2 justify-content-end">
        <b-col sm="4" class="mb-1 mb-md-0 mr-md-3 mr-0">
          <b-input-group>
            <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
            <b-input-group-append is-text>
              <b-icon-search></b-icon-search>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="d-flex justify-content-end" sm="1">
          <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
        </b-col>
      </b-row>
      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <publishers-table v-if="data && data.length" :publishers="data" @toggle-active="handleTogglePublisherActive" @edit-publisher="handleEditAdvertiser" />
        </table-lazy>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BFormSelect, BInputGroup, BIconSearch } from 'bootstrap-vue'
import PublishersTable from '@/components/PublishersTable'
import SidebarRight from '@/components/SidebarRight'
import TableLazy from '@/components/TableLazy'
import { withFormData } from '@/hoc/withFormData'
import { usePage } from '@/use/page'
import { getToastificationErrorParams, getToastificationSuccessParams } from '@/helpers/toasts'
import { UPDATE_PARTNER_VALIDATION, CREATE_PARTNER_VALIDATION } from '@/constants/validation'
import {infiniteScroll} from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import {search} from "@/use/search"
import CreatePublisherForm from "@/components/PartnerForm"
import {createPublisher, fetchPublisher, updatePublisher} from "@/api/publisher"
import {activeToggle} from "@/use/actions/activeToggle"
import {activateAdvertiser, deactivateAdvertiser} from "@/api/advertiser"

const CreateForm = withFormData(CreatePublisherForm, createPublisher, CREATE_PARTNER_VALIDATION)
const UpdateForm = withFormData(CreatePublisherForm, updatePublisher, UPDATE_PARTNER_VALIDATION)

export default {
  components: {
    BCard,
    BRow,
    BInputGroup,
    BIconSearch,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    SidebarRight,
    PublishersTable,
    CreateForm,
    UpdateForm,
    TableLazy
  },
  data() {
    return {
      search: '',
      activeFilter: null,
      isOpenCreateForm: false,
      editAdvertiser: null
    }
  },
  methods: {
    refetchTable() {
      this.isOpenCreateForm = false
      this.editAdvertiser = null
      this.fetchPageData()
    },
    handleEditAdvertiser(id) {
      this.editAdvertiser = this.data.find((u) => u.id === id)
    },
    handleCreate() {
      this.refetchTable()
      this.$toast(getToastificationSuccessParams('Рекламодатель успешно создан'))
    },
    handleUpdate() {
      this.refetchTable()
      this.$toast(getToastificationSuccessParams('Данные рекламодателя успешно обновлены'))
    },
    handleError(e) {
      this.editAdvertiser = null
      // this.isOpenCreateForm = false
      this.$toast(getToastificationErrorParams(e.message))
    }
  },
  setup() {
    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchPublisher)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj } = queries(fetchPageData)
    const { searchRef, startSearch } = search(queryObj)
    const { handleToggleActive } = activeToggle()


    // that's ok for endpoints (/user)
    const handleTogglePublisherActive = (data) => handleToggleActive(data, deactivateAdvertiser, activateAdvertiser)

    return {
      isLoading,
      handleTogglePublisherActive,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll,
      queryObj,
      searchRef,
      startSearch
    }
  }
}
</script>
