<template>
  <b-table :items="publishers" :fields="fields">

    <template #cell(id)="row">
      <p style="word-break: normal; margin: 0;">{{ row.item.id }}</p>
    </template>

    <template #cell(manager_dsp)="row">
      <span>{{ row.item.manager ? row.item.manager.name : '' }}</span>
    </template>

    <template #cell(is_active)="row">
      <!--      <b-badge pill :variant="row.item.is_active ? 'light-success' : 'light-danger'">-->
      <!--        {{ row.item.is_active ? 'Активен' : 'Неактивен' }}-->
      <!--      </b-badge>-->
      <b-form-checkbox v-model="row.item.is_active" variant="success" switch @change="handleToggleActive($event, row.item)" />
    </template>

    <template #cell(actions)="row">
      <b-dropdown right variant="flat-primary" no-caret>
        <template #button-content>
          <more-vertical-icon />
        </template>

        <b-dropdown-item @click="handleEditPublisher(row.item.id)"> Редактировать</b-dropdown-item>
<!--        <b-dropdown-item :to="{ name: 'advertising-campaignsAdvertiser', params: { user_id: row.item.id }}"> Рекламные кампании</b-dropdown-item>-->
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { BTable, BCard, BBadge, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Имя' },
  { key: 'email', label: 'email' },
  { key: 'manager_dsp', label: 'Менеджер' },
  { key: 'is_active', label: 'Статус'},
  { key: 'actions', label: 'Действия' }
]

export default {
  components: {
    BTable,
    BCard,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon
  },
  data() {
    return {
      fields
    }
  },
  props: {
    publishers: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleEditPublisher(id) {
      this.$emit('edit-publisher', id)
    },
    handleToggleActive(event, data) {
      this.$emit('toggle-active', data)
    }
  }
}
</script>
